import findIndex from 'lodash/fp/findIndex';

import insertAt from 'utils/insertAt';

import { LIFESTYLE, SKIN } from './constants/progressCategories';
import cleanserTexture from './questions/cleanserTexture';
import comfort from './questions/comfort';
import concerns from './questions/concerns';
import firmness from './questions/firmness';
import moisturizerTextures from './questions/moisturizerTexture';
import oilLocation from './questions/oilLocation';
import phototype from './questions/phototype';
import profileBackground from './questions/profileBackground';
import routineProducts from './questions/routineProducts';
import geoAggressors from './questions/shared/geoAggressors';
import hydration from './questions/shared/hydration';
import signIn from './questions/shared/signIn';
import sports from './questions/shared/sports';
import stress from './questions/shared/stress';
import stressIntensity from './questions/shared/stressIntensity';
import zipCode from './questions/shared/zipCode';
import age from './questions/skin/age';
import diet from './questions/skin/diet';
import hormones from './questions/skin/hormones';
import indoorExposure from './questions/skin/indoorExposure';
import myPreferencesTransitionScreen from './questions/skin/myPreferencesTransistionScreen';
import yourSkinTransitionScreen from './questions/skin/mySkinTransistionScreen';
import outdoorExposure from './questions/skin/outdoorExposure';
import skinWelcome from './questions/skin/skinWelcome';
import sleep from './questions/skin/sleep';
import vices from './questions/skin/vices';
import skincareFragrance from './questions/skincareFragrance';
import skinGoals from './questions/skinGoals';
import skintypeAfternoon from './questions/skintypeAfternoon';
import skintypeMorning from './questions/skintypeMorning';
import skintypeShower from './questions/skintypeShower';
import yourSkinWrinkles from './questions/yourSkinWrinkles';
import zitsFrequency from './questions/zitsFrequency';
import zitsHormonal from './questions/zitsHormonal';
import zitsLocation from './questions/zitsLocation';
import { MY_LIFESTYLE, MY_SKIN } from './constants';

/**
 * Partial set
 * Contains every data Qs + welcome screen
 * Has to be extended with signIn screen
 * Qs before signIn are public
 */
const partialSet = [
  { ...skinWelcome, public: true },
  // -----------------------------------------------------
  // Lifestyle
  // -----------------------------------------------------
  { ...age, public: true },
  { ...outdoorExposure, public: true },
  { ...sports, route: `/consultation/skincare/${MY_LIFESTYLE}/sports`, public: true },
  { ...indoorExposure, public: true },
  { ...stress, route: `/consultation/skincare/${MY_LIFESTYLE}/stress`, public: true },
  {
    ...stressIntensity,
    route: `/consultation/skincare/${MY_LIFESTYLE}/stress-intensity`,
    public: true,
  },
  { ...sleep, public: true },
  { ...hormones, public: true },
  { ...hydration, route: `/consultation/skincare/${MY_LIFESTYLE}/hydration`, public: true },
  { ...diet, public: true },
  { ...vices, public: true },
  // -----------------------------------------------------
  // Your skin
  // -----------------------------------------------------
  { ...yourSkinTransitionScreen, public: true },
  { ...skintypeMorning, public: true },
  { ...skintypeShower, public: true },
  { ...skintypeAfternoon, public: true },
  { ...phototype, public: true },
  { ...concerns, public: true },
  { ...oilLocation, public: true },
  { ...zitsFrequency, public: true },
  { ...zitsHormonal, public: true },
  { ...zitsLocation, public: true },
  { ...yourSkinWrinkles, public: true },
  { ...firmness, public: true },
  { ...profileBackground, public: true },
  { ...comfort, public: true },
  { ...routineProducts, public: true },
  { ...skinGoals, public: true },
  {
    ...zipCode,
    route: `/consultation/skincare/${MY_LIFESTYLE}/zipcode`,
    public: true,
    category: MY_SKIN,
    progressCategory: SKIN,
  },
  {
    ...geoAggressors,
    route: `/consultation/skincare/${MY_LIFESTYLE}/geo-aggressors`,
    title: 'Here’s what affects your skin in __city__.',
    public: true,
    category: MY_SKIN,
    progressCategory: SKIN,
  },
  // -----------------------------------------------------
  // Preferences
  // -----------------------------------------------------
  myPreferencesTransitionScreen,
  skincareFragrance,
  cleanserTexture,
  moisturizerTextures,
];

/**
 * Default set
 * Signin before the zip code screen
 */
const insertIdx = findIndex(q => q.name === 'zipcode')(partialSet);
export const defaultSet = insertAt(insertIdx, {
  ...signIn,
  // Agnostic components (used in different categories) must be overridden in a question set
  route: '/consultation/skincare/signin',
  progressCategory: SKIN,
  title: 'Almost done. Let’s save your results to move forward.',
})(partialSet);

/**
 * Signin first set
 * Signin after the welcome screen
 */
const firstQuestionIdx = findIndex(q => q.name === 'welcome')(partialSet) + 1;
export const signinFirstSet = insertAt(firstQuestionIdx, {
  ...signIn,
  // Agnostic components (used in different categories) must be overridden in a question set
  route: '/consultation/skincare/signin',
  progressCategory: LIFESTYLE,
})(partialSet);
