import find from 'lodash/fp/find';
import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEqual from 'lodash/fp/isEqual';

import { createSelector } from 'reselect';

import * as Status from 'constants/statuses';

import { getPaymentMethods, getPayPalPaymentMethods } from 'dux/user/selectors';

import initialState from './initialState';

// Get state.accountCards
const getAccountCardsSubState = getOr(initialState, 'accountCards');
// Get state.accountCards.cards
export const getCards = createSelector(getAccountCardsSubState, get('cards'));
// Get state.accountCards.cards[0]
export const getFirstCard = createSelector(getCards, first);
// Get state.accountCards.cards[0].id
export const getFirstCardId = createSelector(getFirstCard, get('id'));
// Get state.accountCards.status
export const getStatus = createSelector(getAccountCardsSubState, get('status'));
// Is state.accountCards.status === 'LOADING'
export const isLoading = createSelector(getStatus, flow(get('status'), isEqual(Status.LOADING)));
// Get state.accountCards.preferredCard
export const getPreferredCardId = createSelector(getAccountCardsSubState, get('preferredCard'));
// state.accountCards.preferredCard === props.card.id
export const isCardDefault = createSelector(
  getPreferredCardId,
  (_state, props) => get('card.id', props),
  (preferredCardId, propsCardId) =>
    preferredCardId && propsCardId && preferredCardId === propsCardId
);
// state.accountCards.preferredCard || state.accountCards.cards[0].id
export const getPreselectedCardId = createSelector(
  getPreferredCardId,
  getFirstCardId,
  (preferredCardId, firstCardId) => preferredCardId || firstCardId
);
// for props { id } returns state.accountCards.cards[x]
export const getCardById = createSelector(
  getCards,
  (_state, { id } = {}) => id,
  (cards, id) => find({ id }, cards)
);

export const getPaypalAndCards = createSelector(
  getAccountCardsSubState,
  getPayPalPaymentMethods,
  (accountCard, paypalAccount) => {
    const paymentMethods = [...paypalAccount];
    if (accountCard?.cards) {
      paymentMethods.push(...accountCard.cards);
    }
    return paymentMethods;
  }
);

export const getPaypalAndCardsForShipNow = createSelector(
  getPaymentMethods,
  getPayPalPaymentMethods,
  (paymentMethods, paypalAccount) => [...paymentMethods, ...paypalAccount]
);
