import auraImg from 'src/assets/images/fragrances/fragrance_aura.jpg';

import * as fragrances from '../../constants/haircareFragrances';

import fragFreeBottleImg from 'assets/images/fragrances/fragrance_bottle.svg';
import candelaImg from 'assets/images/fragrances/fragrance_candela.jpg';
import elixirImg from 'assets/images/fragrances/fragrance_elixir.jpg';
import feteImg from 'assets/images/fragrances/fragrance_fete.jpg';
import fragFreeSignImg from 'assets/images/fragrances/fragrance_free.png';
import darkFragFreeSignImg from 'assets/images/fragrances/fragrance_free_dark.png';
import fruitissimeImg from 'assets/images/fragrances/fragrance_fruitissime.jpg';
import ispahanImg from 'assets/images/fragrances/fragrance_ispahan.jpg';
import napoliImg from 'assets/images/fragrances/fragrance_napoli.jpg';
import palmaImg from 'assets/images/fragrances/fragrance_palma.jpeg';
import perleImg from 'assets/images/fragrances/fragrance_perle.jpg';
import rituelImg from 'assets/images/fragrances/fragrance_rituel.jpg';
import signatureImg from 'assets/images/fragrances/fragrance_signature.jpg';
import vitaeImg from 'assets/images/fragrances/fragrance_vitae.jpg';
import arcadiaImg from 'assets/images/fragrances/prose-arcadia-swatch.jpg';
import botanicaImg from 'assets/images/fragrances/prose-botanica-swatch.jpg';
import corsicaImg from 'assets/images/fragrances/prose-corsica-swatch.jpg';
import meleniImg from 'assets/images/fragrances/prose-meleni-swatch.jpg';
import oasisImg from 'assets/images/fragrances/prose-oasis-swatch.jpg';
import paradisoImg from 'assets/images/fragrances/prose-paradiso-swatch.jpg';
import preludeImg from 'assets/images/fragrances/prose-prelude-swatch.jpg';

export const ARCADIA = {
  name: 'Arcadia',
  slug: fragrances.ARCADIA,
  ingredients: 'Grapefruit, Basil, Cedar',
  description: 'Citrusy + Woody',
  url: arcadiaImg,
  imageAlt: 'Prose arcadia swatch',
};

export const AURA = {
  name: 'Aura',
  slug: fragrances.AURA,
  ingredients: 'Wildflowers, Desert Breeze, Musk',
  description: 'Breezy + Mineral',
  url: auraImg,
};

export const BOTANICA = {
  name: 'Botanica',
  slug: fragrances.BOTANICA,
  ingredients: 'Eucalyptus, Rosemary, Lavender',
  description: 'Herbal + Aromatic',
  url: botanicaImg,
  imageAlt: 'Prose botanica swatch',
};

export const CANDELA = {
  name: 'Candela',
  slug: fragrances.CANDELA,
  ingredients: 'Nutmeg, Vanilla, White Woods',
  description: 'Woody + Warm',
  url: candelaImg,
};

export const CORSICA = {
  name: 'Corsica',
  slug: fragrances.CORSICA,
  ingredients: 'Anjou Pear, Peony, Cedar Wood',
  description: 'Fresh + Aquatic',
  url: corsicaImg,
  imageAlt: 'Prose corsica swatch',
};

export const ELIXIR = {
  name: 'Elixir',
  slug: fragrances.ELIXIR,
  ingredients: 'Freesia, Jasmine, Vanilla',
  description: 'Floral + Beachy',
  url: elixirImg,
};

export const FETE = {
  name: 'Fête',
  slug: fragrances.FETE,
  ingredients: 'Apple, Spiced Pear, Cinnamon',
  description: 'Spiced + Fruity',
  url: feteImg,
};

export const FRUITISSIME = {
  name: 'Fruitissime',
  slug: fragrances.FRUITISSIME,
  ingredients: 'Pineapple, Coconut & Candied Fruit',
  description: 'Fruity + Sweet',
  url: fruitissimeImg,
};

export const ISPAHAN = {
  name: 'Ispahan',
  slug: fragrances.ISPAHAN,
  ingredients: 'Lychee, Rose & Raspberry',
  description: 'Floral + Fruity',
  url: ispahanImg,
};

export const MELENI = {
  name: 'Meleni',
  slug: fragrances.MELENI,
  ingredients: 'Mango, Melon, Lush Greens',
  description: 'Fruity + Tropical',
  url: meleniImg,
  imageAlt: 'Prose meleni swatch',
};

export const NAPOLI = {
  name: 'Napoli',
  slug: fragrances.NAPOLI,
  ingredients: 'Lemon, Neroli & Orange Blossom',
  description: 'Citrusy + Floral',
  url: napoliImg,
};

export const FRAGRANCE_FREE = {
  name: 'Fragrance-Free',
  slug: fragrances.NONE,
  urlsByAssetName: {
    fragFreeBottleImg,
    fragFreeSignImg,
    darkFragFreeSignImg,
  },
  imageAlt: 'Prose fragrance-free swatch',
};

export const OASIS = {
  name: 'Oasis',
  slug: fragrances.OASIS,
  ingredients: 'Jasmine, Amber, White Peach',
  description: 'Floral + Summery',
  url: oasisImg,
  imageAlt: 'Prose oasis swatch',
};

export const PERLE = {
  name: 'Perle',
  slug: fragrances.PERLE,
  ingredients: 'Musk, Gardenia, Sandalwood',
  description: 'Powdery + Musky',
  url: perleImg,
};

export const PRELUDE = {
  name: 'Prelude',
  slug: fragrances.PRELUDE,
  ingredients: 'Rose, Geranium, Blue Iris',
  description: 'Floral + Fresh',
  url: preludeImg,
  imageAlt: 'Prose prelude swatch',
};

export const SIGNATURE = {
  name: 'Signature',
  slug: fragrances.SIGNATURE,
  ingredients: 'Violet Leaf, Peony, White Amber',
  description: 'Floral + Powdery',
  url: signatureImg,
};

export const VITAE = {
  name: 'Vitae',
  slug: fragrances.VITAE,
  ingredients: 'Green Tea, Mandarin, Dewy Grass',
  description: 'Green Floral + Citrusy',
  url: vitaeImg,
};

export const RITUEL = {
  name: 'Rituel',
  slug: fragrances.RITUEL,
  ingredients: 'Green Tea, Mandarin, Bergamot',
  description: 'Green + Citrusy',
  url: rituelImg,
};

export const PALMA = {
  name: 'Palma',
  slug: fragrances.PALMA,
  ingredients: 'Orange, Tea, Sandalwood',
  description: 'Relaxing + Woody',
  url: palmaImg,
};
export const PARADISO = {
  name: 'Paradiso',
  slug: fragrances.PARADISO,
  ingredients: 'Coconut, Vanilla, Tonka Bean',
  description: 'Sweet + Sunlit',
  url: paradisoImg,
  imageAlt: 'Prose paradiso swatch',
  limited: true,
};
