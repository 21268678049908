import { productsCategories } from './products';

export const cartSectionName = {
  PRESELECT: 'preselect',
  RECOMMENDED: 'main',
  UPSALE: 'upsale',
  FEATURED: 'featured',
  OPTIONAL: 'optional',
};

export const HAIRCARE_CART_URL = '/checkout/haircare';
export const SKINCARE_CART_URL = '/checkout/skincare';

export const cartUrlFromCategory = {
  [productsCategories.HAIRCARE]: HAIRCARE_CART_URL,
  [productsCategories.SKINCARE]: SKINCARE_CART_URL,
};
