import * as consultationComponents from '../../constants/consultationComponents';
import { HAIR_SCALP } from '../../constants/progressCategories';

import signInHaircare from 'assets/images/consultation/signIn/sign-in-haircare.jpg';

const signIn = {
  name: 'signin',
  progressCategory: HAIR_SCALP,
  shouldBeIncluded: ({ isAuthenticated }) => !isAuthenticated,
  public: true,
  Component: consultationComponents.SignIn,
  skipSave: true,
  // Content Specific to sign-up / sign-in
  title: "Before we get started, what's your name and email?",
  subtitle: 'Enter your email to continue the consultation and unlock your exclusive offer.',
  descriptionImage: {
    alt: '',
    source: signInHaircare,
    height: 230,
    width: 367,
  },
  terms: (
    <>
      By clicking &apos;NEXT&apos; I acknowledge I have read and agree to the{' '}
      <a href="/terms" target="_blank">
        Prose End User License and Terms of Service
      </a>{' '}
      and{' '}
      <a href="/privacy" target="_blank">
        Privacy Policy
      </a>
    </>
  ),
  signinQuestion: 'Already gave us your email?',
  signinCta: 'Resume here.',
};

export default signIn;
