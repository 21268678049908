import bambooCharcoalImg from 'assets/images/productPages/ingredients/bamboo_charcoal.png';
import cbdPowderImg from 'assets/images/productPages/ingredients/cbd_powder.png';
import eucalyptusOilImg from 'assets/images/productPages/ingredients/eucalyptus_oil.png';
import grapefruitOilImg from 'assets/images/productPages/ingredients/grapefruit_oil.png';
import jojobaBreadsImg from 'assets/images/productPages/ingredients/jojoba_beads.png';
import jujubeBarkExtractImg from 'assets/images/productPages/ingredients/jujube_bark_extract.png';
import kaleExtractImg from 'assets/images/productPages/ingredients/kale_extract.png';
import kombuchaTeaImg from 'assets/images/productPages/ingredients/kombucha_tea.png';
import macaRootImg from 'assets/images/productPages/ingredients/maca_root.png';
import peppermintExtractImg from 'assets/images/productPages/ingredients/peppermint_extract.png';
import pinkClayImg from 'assets/images/productPages/ingredients/pink_clay.png';
import proVitaminB5Img from 'assets/images/productPages/ingredients/pro_vitamin_b5.png';
import wintergreenExtractImg from 'assets/images/productPages/ingredients/wintergreen_extract.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask-in-use.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask-in-use-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask-in-use-mobile.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-pre-shampoo-scalp-mask-products.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-using-pre-shampoo-scalp-mask.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "TRACE'S PURIFYING + PROTECTIVE SCALP MASK",
      ingredients: [
        {
          image: bambooCharcoalImg,
          name: 'Bamboo Charcoal',
          benefit: 'Scalp Detox',
        },
        {
          image: eucalyptusOilImg,
          name: 'Eucalyptus Oil',
          benefit: 'Scalp Health',
        },
        {
          image: kaleExtractImg,
          name: 'Kale Extract',
          benefit: 'Sebum Control',
        },
        {
          image: kombuchaTeaImg,
          name: 'Kombucha Tea',
          benefit: 'Scalp Health',
        },
        {
          image: jujubeBarkExtractImg,
          name: 'Jujube Bark Extract',
          benefit: 'Scalp Cleanser',
        },
        {
          image: jojobaBreadsImg,
          name: 'Jojoba Beads',
          benefit: 'Scalp Cleanser',
        },
      ],
    },
    {
      title: "JOSEPH'S SOOTHING + HYDRATING SCALP MASK",
      ingredients: [
        {
          image: pinkClayImg,
          name: 'Pink Clay',
          benefit: 'Scalp Soothing',
        },
        {
          image: wintergreenExtractImg,
          name: 'Wintergreen Extract',
          benefit: 'Scalp Detox',
        },
        {
          image: jujubeBarkExtractImg,
          name: 'Jujube Bark Extract',
          benefit: 'Scalp Cleanser',
        },
        {
          image: macaRootImg,
          name: 'Maca Root',
          benefit: 'Scalp Stimulation',
        },
        {
          image: proVitaminB5Img,
          name: 'Pro-Vitamin B5',
          benefit: 'Hydratation',
        },
        {
          image: kombuchaTeaImg,
          name: 'Kombucha Tea',
          benefit: 'Scalp Health',
        },
      ],
    },
    {
      title: "MELISSA'S GENTLE + BALANCING SCALP MASK",
      ingredients: [
        {
          image: peppermintExtractImg,
          name: 'Peppermint Extract',
          benefit: 'Sebum Regulator',
        },
        {
          image: grapefruitOilImg,
          name: 'Grapefruit Oil',
          benefit: 'Scalp Soothing',
        },
        {
          image: kaleExtractImg,
          name: 'Kale Extract',
          benefit: 'Scalp Nutrition',
        },
        {
          image: cbdPowderImg,
          name: 'CBD Powder',
          benefit: 'Scalp Soother',
        },
        {
          image: bambooCharcoalImg,
          name: 'Bamboo Charcoal',
          benefit: 'Scalp Detox',
        },
        {
          image: proVitaminB5Img,
          name: 'Pro-Vitamin B5',
          benefit: 'Hydratation',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'vert',
      question: 'Why mask before you shampoo?',
      description:
        'Your hair’s cuticle is more receptive to our soothing mask before it’s washed. It also helps your shampoo give a gentler clean with zero irritation.',
    },
  ],
  faq: [
    {
      question: 'Why do I need a scalp mask?',
      answer: `Healthy hair starts with a healthy scalp. Our new Scalp Mask is highly concentrated in
         active ingredients to help restore balance and purify your scalp with a micro-exfoliating
         texture, leaving your skin soothed and refreshed without buildup or residue. It also
         perfectly prepares your hair for the shampoo and conditioner to come.`,
    },
    {
      question: 'Why does it have a jelly-like texture?',
      answer: `We use two naturally-derived thickeners (sclerotium gum and corn starch) into our Scalp Mask, along with white and pink clays that give a creamy-jelly
         texture to the formula and make it easier to apply and distribute evenly on the scalp.`,
    },
    {
      question: 'Will it make my roots oily?',
      answer:
        'No, our Scalp Mask will deposit a light and thin moisturizing layer on the scalp keeping it hydrated, with none of the oiliness.',
    },
    {
      question: 'How is it different from other scalp masks?',
      answer: `Our Mask is highly concentrated in natural actives and customized to address your exact scalp issues.
        It's also freshly made to order to keep the actives potent--traditional hair masks decrease in potency while they wait on the shelf for months or years.
        Plus, our Scalp Mask is completely dedicated to scalp health while most formulas on the market also include conditioning actives that are not suitable for the scalp.`,
    },
    {
      question:
        "Why can't I choose my fragrance on the Scalp Mask like I can with other Prose products?",
      answer: `Since it requires an extended time on the scalp, we want to be sensitive to any possible, well, sensitivities.
          So instead of adding a perfume, we use super-soothing eucalyptus or grapefruit oils for a light, natural scent that's never irritating.`,
    },
    {
      question: 'Can Prose Custom Scalp Mask treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom scalp mask packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom scalp mask in use',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using scalp mask',
    },
    {
      image: carouselImg4,
      alt: 'Assortment of Prose custom scalp mask containers',
    },
  ],
  noListModule: {
    description:
      'Every ingredient in your Custom Scalp Mask is rigorously researched, backed by science, and precisely selected to target your unique scalp goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A scalp mask with naturally powerful + proven-effective ingredients',
  },
  formulaModule: {
    header: (
      <>
        A personalized scalp mask,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every scalp mask we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose custom scalp mask in use',
    },
  },
};
