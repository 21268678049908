import acaciaCollagenImg from 'assets/images/productPages/ingredients/acacia_collagen.png';
import arganOilImg from 'assets/images/productPages/ingredients/argan_oil.png';
import arginineImg from 'assets/images/productPages/ingredients/arginine.png';
import baobabExtractImg from 'assets/images/productPages/ingredients/baobab_extract.png';
import caviarLimeExtractImg from 'assets/images/productPages/ingredients/caviar_lime_extract.png';
import coconutOilImg from 'assets/images/productPages/ingredients/coconut_oil.png';
import flaxSeedExtractImg from 'assets/images/productPages/ingredients/flax_seed_extract.png';
import hyaluronicAcidImg from 'assets/images/productPages/ingredients/hyaluronic_acid.png';
import jojobaOilImg from 'assets/images/productPages/ingredients/jojoba_oil.png';
import lilacExtractImg from 'assets/images/productPages/ingredients/lilac_extract.png';
import peaExtractImg from 'assets/images/productPages/ingredients/pea_extract.png';
import pequiOilImg from 'assets/images/productPages/ingredients/pequi_oil.png';
import sheaOilImg from 'assets/images/productPages/ingredients/shea_oil.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-curl-cream.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-curl-cream-products.jpg';
import carouselImg4 from 'assets/images/productPages/prose-model-posing-with-custom-curl-cream.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-using-custom-curl-cream.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-using-custom-curl-cream-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-using-custom-curl-cream-mobile.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "MILENE'S SHINE + HEAT PROTECTANT CURL CREAM",
      ingredients: [
        {
          image: pequiOilImg,
          name: 'Pequi Oil',
          benefit: 'Anti-Frizz',
        },
        {
          image: lilacExtractImg,
          name: 'Lilac Extract',
          benefit: 'Hair Health',
        },
        {
          image: caviarLimeExtractImg,
          name: 'Caviar Lime Extract',
          benefit: 'Shine',
        },
        {
          image: arganOilImg,
          name: 'Argan Oil',
          benefit: 'Nourishment',
        },
        {
          image: arginineImg,
          name: 'Arginine',
          benefit: 'Heat Protect',
        },
        {
          image: acaciaCollagenImg,
          name: 'Acacia Collagen',
          benefit: 'Fiber Repairing',
        },
      ],
    },
    {
      title: "ERIN'S NOURISHING + HYDRATING CURL CREAM",
      ingredients: [
        {
          image: baobabExtractImg,
          name: 'Baobab Extract',
          benefit: 'Fiber Repairing',
        },
        {
          image: sheaOilImg,
          name: 'Shea Oil',
          benefit: 'Nourishment',
        },
        {
          image: coconutOilImg,
          name: 'Coconut Oil',
          benefit: 'UV Control',
        },
        {
          image: jojobaOilImg,
          name: 'Jojoba Oil',
          benefit: 'Nourishment',
        },
        {
          image: flaxSeedExtractImg,
          name: 'Linseed Extract',
          benefit: 'Curl Definition',
        },
        {
          image: hyaluronicAcidImg,
          name: 'Hyaluronic Acid',
          benefit: 'Hydration',
        },
      ],
    },
    {
      title: "CHRIS'S REPARATIVE + ANTI-FRIZZ CURL CREAM",
      ingredients: [
        {
          image: flaxSeedExtractImg,
          name: 'Linseed Extract',
          benefit: 'Curl Definition',
        },
        {
          image: pequiOilImg,
          name: 'Pequi Oil',
          benefit: 'Anti-Frizz',
        },
        {
          image: acaciaCollagenImg,
          name: 'Acacia Collagen',
          benefit: 'Fiber Repairing',
        },
        {
          image: lilacExtractImg,
          name: 'Lilac Extract',
          benefit: 'Hair Health',
        },
        {
          image: peaExtractImg,
          name: 'Pea Extract',
          benefit: 'Heat Protection',
        },
        {
          image: baobabExtractImg,
          name: 'Baobab Extract',
          benefit: 'Fiber Repairing',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'vert',
      number: '213',
      description:
        'Apply Custom Curl Cream to dry, detangled hair prior to twist-outs, rod sets, or bantu knots. Custom Curl Cream allows for soft, lightweight hold without any dryness or flaking.',
    },
    {
      background: 'vert',
      number: '572',
      description:
        'To revive curls in between washes, start by lightly dampening your lengths. Apply a small amount of custom curl cream to individual curls to hydrate and de-frizz. No need to rake through—simply finish by scrunching or air-drying.',
    },
    {
      background: 'vert',
      number: '106',
      description:
        'Apply custom curl cream on dry, detangled lengths prior to twist-outs, rod sets or bantu knots. Custom curl cream allows for soft, lightweight hold without any dryness or flaking.',
    },
  ],
  faq: [
    {
      question: 'What is Curl Cream?',
      answer:
        'Prose Curl Cream is a multipurpose product that offers hydration, definition, and a bit of hold. With an airy, lotion-like texture, it smooths and defines your natural curls without compromising volume or leaving behind crunch or residue. It can be used alone or in combination with other custom styling products.',
    },
    {
      question: 'What hair textures does Prose Custom Curl Cream work for?',
      answer: `Prose Curl Cream is custom-formulated for hair textures ranging from Type 2 waves to Type 3 curls and Types 4A, 4B, and 4C coils. It defines your natural curl pattern and provides light hold for a variety of styles without a stiff finish.`,
    },
    {
      question: 'How do I customize my Curl Cream?',
      answer: (
        <>
          Creating your own Custom Curl Cream formula is as easy as 1, 2, 3:
          <br />
          1. Take our in-depth online consultation so we can understand what kind of care your hair
          needs. <br />
          2. We’ll use 85+ factors from your consultation to customize your Curl Cream to your
          hair’s unique needs. <br />
          3. Share feedback on your formula via Review and Refine® so we can provide evolving,
          always custom-fitting care.
        </>
      ),
    },
    {
      question: 'How do I use Prose Custom Curl Cream?',
      answer:
        'Custom Curl Cream can be applied to damp or dry hair to help with hair styling and minimizing unwanted frizz. Your Custom Curl Cream order will arrive with your detailed routine.',
    },
    {
      question: 'Is Prose Custom Curl Cream color-safe?',
      answer:
        'Yes — all Prose products are color-safe, and if you indicate color treatments during the consultation, your Custom Curl Cream may contain additional ingredients for color protection.',
    },
    {
      question: 'Is Prose Curl Cream curl-friendly?',
      answer:
        'All Custom Curl Cream formulas are vegan, cruelty-free, sulfate-free, paraben-free, pthalate-free, and alcohol-free. You can also opt for a silicone-free and/or fragrance-free formula during your consultation.',
    },
    {
      question: 'Is Prose Custom Curl Cream Curly Girl Method-approved?',
      answer: `Yes, our Custom Curl Cream was developed with curl experts and can be formulated to adhere to the Curly Girl Method. It does not contain any alcohol, sulfates, or parabens, and you can opt for a fragrance-free formula during your consultation. Custom Curl Cream contains water-dispersible silicone, though you can opt for silicone-free if you'd like.`,
    },
    {
      question: 'Can Prose Custom Curl Cream treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom curl cream packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose-custom-curl-cream-products',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom curl cream',
    },
    {
      image: carouselImg4,
      alt: 'Prose model posing with bottle of custom curl cream',
    },
  ],
  formulaModule: {
    header: (
      <>
        A personalized curl cream,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every curl cream we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Smiling woman with long brown curls swept across face',
    },
  },
  numbersModule: {
    title: 'A curl cream scientifically tested for efficacy',
    numbers: [
      { number: '24 hr', label: 'curl hold, frizz control, and humidity protection*' },
      { number: '80%', label: 'frizz reduction after one use*' },
      { number: '80%', label: 'curl retention after one use*' },
    ],
    disclaimer: '*Instrumental test in high humidity conditions.',
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Curl Cream is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A curl cream with naturally powerful + proven-effective ingredients',
  },
};
