export const feedbackCategories = {
  HAIRCARE: 'haircare',
  SKINCARE: 'skincare',
};

export const feedbackSubCategories = {
  SUPPLEMENTS: 'supplements',
  TOPICALS: 'topicals',
};

export const feedbackUXCategories = [
  feedbackCategories.HAIRCARE,
  feedbackCategories.SKINCARE,
  feedbackSubCategories.SUPPLEMENTS,
];
