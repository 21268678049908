import { productsCategories } from 'constants/products';

export const FIRST_CONSULTATION_ROUTE = '/consultation';
export const FIRST_HAIRCARE_QUESTION_ROUTE = '/consultation/haircare/my-hair/age';
export const FIRST_SKINCARE_QUESTION_ROUTE = '/consultation/skincare/my-lifestyle/age';
export const RETURNING_HAIRCARE_CONSULTATION_ROUTE = '/consultation/haircare?is-returning=true';
const RETURNING_SKINCARE_CONSULTATION_ROUTE = '/consultation/skincare?is-returning=true';
export const HAIRCARE_FINAL_ROUTE = '/consultation/haircare/results/get-results';
export const SKINCARE_FINAL_ROUTE = '/consultation/skincare/results/get-results';

export const SIGN_IN_FIRST_QUERY = '?signin-first=true';
export const SIGN_IN_FIRST_HAIRCARE_CONSULTATION =
  '/consultation/haircare/signin?signin-first=true';

const SIGN_IN_FIRST_SKINCARE_CONSULTATION = '/consultation/skincare/signin?signin-first=true';

export const STYLING_GEL_CONSULTATION_QUESTION_SET_KEY = 'styling-gel';
export const SUPPLEMENTS_CONSULTATION_QUESTION_SET_KEY = 'supplements';

export const returningConsultationRouteByCategory = {
  [productsCategories.HAIRCARE]: RETURNING_HAIRCARE_CONSULTATION_ROUTE,
  [productsCategories.SKINCARE]: RETURNING_SKINCARE_CONSULTATION_ROUTE,
};

export const signInFirstConsultationRouteByCategory = {
  [productsCategories.HAIRCARE]: SIGN_IN_FIRST_HAIRCARE_CONSULTATION,
  [productsCategories.SKINCARE]: SIGN_IN_FIRST_SKINCARE_CONSULTATION,
};

export const MY_HAIR = 'my-hair';
export const MY_TREATMENTS = 'my-treatments';
export const MY_LIFESTYLE = 'my-lifestyle';
export const MY_PREFERENCES = 'my-preferences';
export const MY_GOALS = 'my-goals';
export const RESULTS = 'results';
export const SIGNIN = 'signin';
export const MY_SKIN = 'my-skin';

export const HAIRCARE_QUESTION_LENGTH = 'length';
export const HAIRCARE_QUESTION_TEXTURE = 'texture';
export const HAIRCARE_QUESTION_TYPE = 'type';
export const SKINCARE_QUESTION_OUTDOOR_EXPOSURE = 'outdoor-exposure';
export const SKINCARE_QUESTION_SPORTS = 'sports';
export const SKINCARE_QUESTION_INDOOR_EXPOSURE = 'indoor-exposure';

// if need to go to oil cart
// export const URL_CART_OIL = '/checkout?origin=oil';
