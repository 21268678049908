export const ExpressCheckoutAbTest = {
  TEST_ID: 'LixCqDh-QF-7qH7FKmJYDQ',
  name: 'Express Checkout AB Test - November 2021',
};

export const GrowthbookFakeExperimentExperiment = {
  TEST_ID: 'oHUXOZBgQHy7KCSh97Wjbg',
  name: 'GrowthBook Fake Experiment',
};

export const ShipNowExperiment = {
  TEST_ID: '69f993F50d20cd09C98198',
  name: 'Ship Now Launch AB test - February 2024',
};

export const SubscribeSaveCheckoutModuleExperiment = {
  TEST_ID: 'e6bbd55979c051d98c73758c5e24b9a225928176778ad9c7a66d89b9c8ce7f36',
  name: 'Subscribe and save module at checkout',
};
export const FeedbackAwarenessCtaExperiment = {
  TEST_ID: '8d3bcb79c46428d165b23333ab9ebe39565579a98c4656d1b4c666a005836735',
  name: 'AB Test - "Refine your formula" vs "Provide feedback"',
};
export const SkincareMinisFrequencyExperiment = {
  TEST_ID: 'e612bdac7835631fa125eb5a30da4567f95f27ed6974d3c51ed2d7d6d2203cce',
  name: 'SSS 8-week Default Frequency AB Test',
};
export const PostPurchasePromoLayoutExperiment = {
  TEST_ID: 'f5251fd3efa92646d5a7b45e97567a12d1f46b8a4fae6a389e59f37393efe5ff',
  name: 'Post Purchase Promo Layout AB Test - September 2024',
};

export const ConsultationQ1EmailCaptureOptimizationExperiment = {
  TEST_ID: '34dda555a978bb3553ead8f5f13576e454db7fe35bded14bcdaed9310ff4f672',
  name: 'ABC test - Q1 + Email Capture Optimizations in Consultation ',
};
export const SkincareConcernsOrderExperiment = {
  TEST_ID: 'b471cae68150ccf2c376b36318ee95365d36e2ca5c3c2d66f0b1c01dc9886b8b',
  name: 'skincare-concerns-order',
};

export const SssShippingFeeExperiment = {
  TEST_ID: 'f357255cf84500bce5a4ac6fa936ac3cd6116bb71e278b97c43ef57e49d2c0bc',
  name: 'Shipping Fee AB Test',
};
