export const couponLabelFromOrigin = origin => {
  switch (origin) {
    case 'referral_advocate':
      return 'Credit';
    case 'referral_code':
      return 'Discount';
    case 'gift':
      return 'Gift Set';
    default:
      return 'Discount';
  }
};
