import { MY_TREATMENTS } from '../constants';
import { TREATMENTS } from '../constants/progressCategories';

const myTreatmentsStyles = {
  name: 'styles',
  category: MY_TREATMENTS,
  progressCategory: TREATMENTS,
  route: `/consultation/haircare/${MY_TREATMENTS}/styles`,
  multi: true,
  title: 'What do you use to style your hair?',
  subtitle: 'Select all that apply, even ones you use infrequently',
  tip: {
    title: 'Why we ask:',
    content:
      'This tells us what level of heat protection you need on a daily basis, and how much build-up your shampoo needs to sweep clean every wash.',
  },
  options: [
    { value: 'hair_dryer', label: 'Blow dryer' },
    { value: 'straightener', label: 'Straightening iron' },
    { value: 'curling', label: 'Curling iron / wand' },
    { value: 'paste_pomade', label: 'Paste / Pomade' },
    { value: 'gel_jelly', label: 'Gel / Jelly' },
    { value: 'curl_cream', label: 'Curl Cream' },
    { value: 'leavein_conditioner', label: 'Leave-In conditioner' },
    { value: 'dry_shampoo', label: 'Dry Shampoo' },
    { value: 'hairspray', label: 'Hairspray' },
    { value: 'mousse', label: 'Mousse' },
    { value: 'hair_oil', label: 'Hair Oil' },
    { value: 'hair_butter', label: 'Hair Butter' },
    {
      value: 'none',
      label: 'No stylers for me',
      unique: true,
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_styling,
  getAnswers: ({ selected }) => ({ diag_styling: selected }),
};

export default myTreatmentsStyles;
