import { createSelector } from 'reselect';
import type { RootState } from 'store';

import { giftContent } from 'assets/content/gift';

import { createGiftMutation } from './apiSlice';

export const getGiftContent = createSelector(() => giftContent());

// ------ local state (slice, NOT apiSlice) ------
const getGifting = (state: RootState) => state.gifting;
export const getSelectedGift = createSelector(getGifting, (gifting) => gifting.selectedGift);
export const getGiftPubkey = createSelector(
  getSelectedGift,
  (selectedGift) => selectedGift?.pubkey,
);

// ------ network state (apiSlice) ------
const getCreateGift = createGiftMutation.select({
  requestId: undefined,
  fixedCacheKey: 'shared-create-gift-mutation',
});
export const getGiftError = createSelector(getCreateGift, (state) => state.error);
