import clone from 'lodash/clone';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

import { createSelector } from 'reselect';

import { MY_LIFESTYLE } from 'Apps/Consultation/constants';
import * as consultationComponents from 'Apps/Consultation/constants/consultationComponents';
import { HAIR_SCALP, LIFESTYLE } from 'Apps/Consultation/constants/progressCategories';
import { defaultSet as hairQuestionSet } from 'Apps/Consultation/hairQuestionSet';
import myLifestyleTransitionScreen from 'Apps/Consultation/questions/myLifestyleTransitionScreen';
import myPreferencesTransitionScreen from 'Apps/Consultation/questions/myPreferencesTransitionScreen';
import myTreatmentsTransitionScreen from 'Apps/Consultation/questions/myTreatmentsTransitionScreen';
import geoAggressors from 'Apps/Consultation/questions/shared/geoAggressors';
import signIn from 'Apps/Consultation/questions/shared/signIn';

import { productsCategories } from 'constants/products';

import insertAt from 'utils/insertAt';

const getState = state => state?.questionSets;

const getQuestionsSetsByConsultationCategory = createSelector(getState, get('data'));

const componentLists = {
  zipcode: consultationComponents.ZipCodeContainer,
  goals: consultationComponents.Goals,
  fragrance: consultationComponents.FragranceContainer,
};

const transitionPagesBySectionName = {
  treatments: { ...myTreatmentsTransitionScreen, public: true },
  lifestyle: { ...myLifestyleTransitionScreen, public: true },
  'my-preferences': myPreferencesTransitionScreen,
};

const normalizeData = questions =>
  questions?.map(question => {
    const {
      getAnswers,
      CardProps,
      getInitialValue,
      shouldBeIncluded,
      options,
      tip,
      optionsFilter,
      icon,
      public: isPublic,
      ModalComponent,
      requiredOptionsForModalToOpen,
      skipSave,
    } = find(hairQuestionSet(), {
      name: question.slug,
    });

    return {
      name: question.slug,
      title: question.title,
      subtitle: question.excerpt,
      multi: question.question_type === 'multi_choice',
      route: question.url_path,
      Component: componentLists[question.question_type],
      options:
        (!isEmpty(question.answers) &&
          question.answers.map((answer, index) => {
            const { imageUrl, unique } = options[index];
            let booleanFormattedValue = answer.value;
            // To remove when API cast value to boolean
            if (booleanFormattedValue === 'True') {
              booleanFormattedValue = true;
            }
            if (booleanFormattedValue === 'False') {
              booleanFormattedValue = false;
            }
            return {
              label: answer.title,
              description: answer.excerpt,
              value:
                question.answer_type === 'int' ? parseInt(answer.value, 10) : booleanFormattedValue,

              // to be replaced by api values
              imageUrl,
              unique,
            };
            // to be replaced by api values when options are dynamic from previous answers
          })) ||
        options,
      progressCategory: question.section,

      // to be replaced by api values
      skipSave,
      CardProps,
      optionsFilter,
      icon,
      requiredOptionsForModalToOpen,
      ModalComponent,
      public: isPublic,
      tip,
      getAnswers,
      getInitialValue,
      shouldBeIncluded,
    };
  });

export const getNormalizedHaicareQuestions = createSelector(
  flow(getQuestionsSetsByConsultationCategory, get(productsCategories.HAIRCARE)),
  data => normalizeData(data?.questions)
);

const getHaircareSectionsList = createSelector(
  flow(getQuestionsSetsByConsultationCategory, get(productsCategories.HAIRCARE)),
  data => Object.keys(groupBy(data?.questions, 'section'))
);

const getHaircareQuestionSetWithTransitionPages = createSelector(
  getNormalizedHaicareQuestions,
  getHaircareSectionsList,
  (normalizedQuestions, sectionsList) => {
    const transitionIndexList = filter(
      sectionsList.map(section =>
        findIndex(
          normalizedQuestions,
          (question, index) =>
            section === question.progressCategory &&
            normalizedQuestions[index + 1] !== undefined &&
            normalizedQuestions[index + 1].progressCategory !== section
        )
      ),
      index => index !== -1
    );
    let questionsWithTransitions = clone(normalizedQuestions);
    transitionIndexList.forEach((index, arrayIndex) => {
      questionsWithTransitions = insertAt(
        index + arrayIndex + 1,
        transitionPagesBySectionName[Object.keys(transitionPagesBySectionName)[arrayIndex]]
      )(questionsWithTransitions);
    });
    return questionsWithTransitions;
  }
);

export const getHaircareQuestionSetForConsultation = createSelector(
  getHaircareQuestionSetWithTransitionPages,
  haircareQuestionSetWithTransitionPages => {
    const questionSetWithGeoAggressors = insertAt(
      findIndex(haircareQuestionSetWithTransitionPages, q => q.name === 'zipcode') + 1,
      { ...geoAggressors, route: `/consultation/haircare/${MY_LIFESTYLE}/geo-aggressors` }
    )(haircareQuestionSetWithTransitionPages);

    // Insert Signin page when public is false
    return insertAt(
      findIndex(questionSetWithGeoAggressors, q => !q.public),
      {
        ...signIn,
        // Agnostic components (used in different categories) must be overridden in a question set
        route: '/consultation/haircare/signin',
        progressCategory: LIFESTYLE,
        title: 'Almost done. Let’s save your results to move forward.',
      }
    )(questionSetWithGeoAggressors);
  }
);

export const getHaircareQuestionSetForConsultationWithSignInFirst = createSelector(
  getHaircareQuestionSetWithTransitionPages,
  normalizedQuestions =>
    insertAt(0, {
      ...signIn,
      // Agnostic components (used in different categories) must be overridden in a question set
      route: '/consultation/haircare/signin',
      progressCategory: HAIR_SCALP,
    })(normalizedQuestions)
);
