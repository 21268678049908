import aloeVeraJuiceImg from 'assets/images/productPages/ingredients/aloe-vera-juice.png';
import binchotanCharcoalImg from 'assets/images/productPages/ingredients/binchotan-charcoal.png';
import mentholImg from 'assets/images/productPages/ingredients/menthol.png';
import oatFlourImg from 'assets/images/productPages/ingredients/oat-flour.png';
import tapiocaStarchImg from 'assets/images/productPages/ingredients/tapioca-starch.png';
import teaTreeOilImg from 'assets/images/productPages/ingredients/tea-tree-oil.png';
import whiteClayImg from 'assets/images/productPages/ingredients/white-clay.png';
import witchHazelExtractImg from 'assets/images/productPages/ingredients/witch-hazel-extract.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-dry-shampoo.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-dry-shampoo-close-up.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-dry-shampoo-in-use.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-posing-with-custom-dry-shampoo.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-using-custom-dry-shampoo-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-using-custom-dry-shampoo-mobile.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "MATHILDE'S REFRESHING + VOLUMIZING DRY SHAMPOO",
      ingredients: [
        {
          image: mentholImg,
          name: 'Menthol',
          benefit: 'Freshness',
        },
        {
          image: tapiocaStarchImg,
          name: 'Tapioca Starch',
          benefit: 'Volume',
        },
        {
          image: witchHazelExtractImg,
          name: 'Witch Hazel Extract',
          benefit: 'Sebum Control',
        },
      ],
    },
    {
      title: "ALINE'S SOOTHING + DETOXIFYING DRY SHAMPOO",
      ingredients: [
        {
          image: teaTreeOilImg,
          name: 'Tea Tree Oil',
          benefit: 'Scalp Detox',
        },
        {
          image: oatFlourImg,
          name: 'Oat Flour',
          benefit: 'Scalp Soothing',
        },
        {
          image: binchotanCharcoalImg,
          name: 'Binchotan Charcoal',
          benefit: 'Scalp Detox',
        },
      ],
    },
    {
      title: "EMILY'S PURIFYING + CALMING DRY SHAMPOO",
      ingredients: [
        {
          image: whiteClayImg,
          name: 'White Clay',
          benefit: 'Sebum Control',
        },
        {
          image: aloeVeraJuiceImg,
          name: 'Aloe Vera Juice',
          benefit: 'Scalp Soothing',
        },
        {
          image: tapiocaStarchImg,
          name: 'Tapioca starch',
          benefit: 'Volume',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'rouge',
      number: '917',
      description:
        'For all-over coverage, spray custom dry shampoo directly onto a brush or fingers, then run through roots and lengths.',
    },
    {
      background: 'rouge',
      number: '790',
      description:
        'For an overnight fix, apply custom dry shampoo before bed. The powder will absorb oil as you sleep, so you and your strands can wake up feeling refreshed.',
    },
    {
      background: 'rouge',
      number: '626',
      description:
        'Not ready to part ways with your blowout? Revive any style by applying custom dry shampoo to roots and lengths.',
    },
    {
      background: 'rouge',
      number: '102',
      description:
        'For an extra boost of volume, apply custom dry shampoo and let it sit for one minute. Flip your hair upside down and lift the roots with your fingers, then tousle the lengths to add body.',
    },
    {
      background: 'rouge',
      number: '860',
      description:
        "Custom dry shampoo isn't just for off-days—apply on clean, dry hair to add texture and grip for easier styling.",
    },
    {
      background: 'rouge',
      number: '352',
      description:
        'For the most precise application and to avoid any fallout, always press down gently on the spray pump—a little pump goes a long way.',
    },
  ],
  faq: [
    {
      question: 'Is Prose custom dry shampoo only for fine, oily hair?',
      answer:
        "Prose dry shampoo is custom-formulated for every individual, and isn't limited to one hair or scalp type. It delivers the right amount of oil absorption for your needs, whether you're a daily washer or go several days in between. With additional ingredients to improve scalp health, boost volume, and so much more, Prose custom dry shampoo is truly just for you.",
    },
    {
      question: 'How do I use Prose custom dry shampoo?',
      answer:
        'Your custom dry shampoo order will arrive with your detailed routine. We generally recommend parting your hair into sections, and spraying directly onto the roots (press down gently—a little pump goes a long way). Let the powder sit for 1 minute, then massage into the scalp and brush or blow-out any excess.',
    },
    {
      question:
        'I have dermatitis/psoriasis/a sensitive scalp—can I still use Prose custom dry shampoo?',
      answer:
        "Yes—Prose custom dry shampoo is safe for those with a very sensitive scalp. It also works to address scalp health through gentle, non-irritating ingredients, and contains additional soothing actives for those who need it. Remember, always consult a doctor if you're experiencing a severe medical condition.",
    },
    {
      question: 'Does Prose custom dry shampoo contain fragrance?',
      answer:
        'Prose custom dry shampoo has no added perfume or fragrance, and is completely fragrance-free.',
    },
    {
      question: 'Can custom dry shampoo meet my Prose ingredient preferences?',
      answer:
        "There's no need to worry. All Prose custom dry shampoo formulas are silicone-free and vegan.",
    },
    {
      question: 'What ingredients are NOT used in Prose custom dry shampoo? ',
      answer:
        'Prose custom dry shampoo is free from the following: toxic aerosol gases, nanoparticles, talc, alcohol, fragrance, silicone, phthalates, and GMOs. Like all Prose products, dry shampoo is cruelty-free.',
    },
    {
      question: 'Can Prose Custom Dry Shampoo treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom dry shampoo packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom dry shampoo in use',
    },
    {
      image: carouselImg3,
      alt: 'Prose model posing with bottle of custom dry shampoo',
    },
    {
      image: carouselImg4,
      alt: 'Prose custom dry shampoo packaging shown close up',
    },
  ],
  noListModule: {
    description:
      'Every ingredient in your Custom Dry Shampoo is rigorously researched, backed by science, and precisely selected to target your unique hair and scalp goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A dry shampoo with naturally powerful + proven-effective ingredients',
  },
  formulaModule: {
    header: (
      <>
        A personalized dry shampoo,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every dry shampoo we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model using custom dry shampoo',
    },
  },
};
