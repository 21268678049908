import get from 'lodash/fp/get';

import { createSelector } from 'reselect';

const getCheckoutState = state => state.checkout;

const getPaymentState = get('checkout.payment');

const getPayPalState = get('checkout.paypal');

export const getIsPaymentReady = createSelector(getPaymentState, get('isPaymentReady'));

export const getPayPalPaymentStatus = createSelector(getPayPalState, get('status'));

export const getStripeToken = createSelector(getCheckoutState, state => state.stripe.token);

export const getStripeCard = createSelector(getStripeToken, token => token && token.card);
