import find from 'lodash/fp/find';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import isNil from 'lodash/fp/isNil';

import { createSelector } from 'reselect';

import currencies, { countriesCodeCurrency } from 'constants/currencies';
import { productsCategories, productsSlugs } from 'constants/products';

import getOrNil from 'utils/getOrNil';
import { formatPhone } from 'utils/textUtils';

const getUserState = get('user');

export const getUser = createSelector(getUserState, get('user'));
export const getUserError = createSelector(getUserState, get('error'));
export const getUserReferral = createSelector(getUserState, get('referral'));

export const getRootToast = createSelector(getUserState, get('rootToast'));
export const getShouldDisplayToast = createSelector(getRootToast, get('shouldDisplayToast'));
export const getRootToastContent = createSelector(getRootToast, get('content'));
export const getRootToastStyle = createSelector(getRootToast, get('style'));
export const getIsToastPermanent = createSelector(getRootToast, get('isPermanent'));

export const getUserIsLoading = createSelector(getUserState, get('isLoading'));
// userIsLoading is set to false at init state (= even when user is null)
export const getUserIsLoadingOrEmpty = createSelector(
  getUserState,
  getUserIsLoading,
  (userState, userIsLoading) => {
    return userIsLoading || isEmpty(userState.user);
  }
);
export const getUserFirstName = createSelector(getUser, getOrNil('', 'first_name'));
export const getUserLastName = createSelector(getUser, getOrNil('', 'last_name'));
export const getUserEmail = createSelector(getUser, get('email'));
export const getTextOptin = createSelector(getUser, getOr(true, 'text_optin'));
export const getTextMarketingOptin = createSelector(getUser, getOr(false, 'text_marketing_optin'));
export const getUserPhone = createSelector(getUser, user => {
  try {
    return formatPhone(user.phone);
  } catch {
    return '';
  }
});
export const getUserPubkey = createSelector(getUser, get('pubkey'));
export const getUserGeolocationGuessedCountry = createSelector(
  getUserState,
  get('geolocationGuessedCountry')
);
export const getUserGeolocationGuessedRegion = createSelector(
  getUserState,
  get('geolocationGuessedRegion')
);
export const getUserStylist = createSelector(getUser, get('stylist'));
// Used in subscription pages (summary and edit)
export const getPrefShipPumps = createSelector(getUser, getOrNil(false, 'pref_ship_pumps'));
export const getCustomerOrigin = createSelector(getUser, get('customer_origin'));
export const getUserPrefCurrency = createSelector(getUser, get('preferred_currency'));
export const getUserHasAcceptedTerms = createSelector(
  getUser,
  getOrNil(false, 'has_accepted_terms')
);
export const getReferralBalance = createSelector(getUser, getOrNil(0, 'referral.balance'));
export const getUserProfile = createSelector(getUser, get('profile'));
export const getIsOrderFree = createSelector(getUserProfile, getOrNil(false, 'is_next_order_free'));
export const getHaveOrders = createSelector(getUserProfile, getOrNil(false, 'has_order'));
export const getHaveOrdersWithFormulas = createSelector(
  getUserProfile,
  getOrNil(false, 'has_order_with_formulas')
);
const getHairProfileStatus = createSelector(getUserProfile, get('hair_profile_status'));
export const getHasCompletedHairProfileFromApi = createSelector(
  getHairProfileStatus,
  status => status === 'completed'
);

export const getHasAnsweredSupplementsQuestions = createSelector(
  getUserProfile,
  get('has_answered_supplements_questions')
);

export const getHasAnsweredStylingGelQuestions = createSelector(
  getUserProfile,
  get('has_answered_styling_gel_questions')
);
export const getConsultationsStatuses = createSelector(getUser, get('consultation_statuses'));
const getHaircareConsultationStatus = createSelector(
  getConsultationsStatuses,
  get(productsCategories.HAIRCARE)
);
const getSkincareConsultationStatus = createSelector(
  getConsultationsStatuses,
  get(productsCategories.SKINCARE)
);
export const getHasNotStartedHaircareConsultation = createSelector(
  getHaircareConsultationStatus,
  isEqual('not_started')
);
export const getHasDraftHaircareConsultation = createSelector(
  getHaircareConsultationStatus,
  isEqual('draft')
);
export const getHasCompletedHaircareConsultation = createSelector(
  getHaircareConsultationStatus,
  isEqual('completed')
);
export const getHasNotStartedSkincareConsultation = createSelector(
  getSkincareConsultationStatus,
  isEqual('not_started')
);
export const getHasDraftSkincareConsultation = createSelector(
  getSkincareConsultationStatus,
  isEqual('draft')
);

export const getHasCompletedSkincareConsultation = createSelector(
  getSkincareConsultationStatus,
  isEqual('completed')
);

export const getHasCompletedAllConsultations = createSelector(
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
  (hasHaircareConsultationComplete, hasSkincareConsultationComplete) =>
    hasHaircareConsultationComplete && hasSkincareConsultationComplete
);

export const getHasActiveSubscription = createSelector(
  getUserProfile,
  getOrNil(false, 'has_active_subscription')
);

export const getHasActiveHairTopicalsSubscription = createSelector(
  getUserProfile,
  getOrNil(false, 'has_active_haircare_subscription')
);
// Haircare topicals or supplements active, cancelled or on hold
export const getHasHaircareSubscriptionInAnyState = createSelector(
  getUserProfile,
  getOrNil(false, 'has_haircare_subscription_in_any_state')
);
export const getHasActiveSkincareSubscription = createSelector(
  getUserProfile,
  getOrNil(false, 'has_active_skincare_subscription')
);
// Skincare active, cancelled or on hold
export const getHasSkincareSubscriptionInAnyState = createSelector(
  getUserProfile,
  getOrNil(false, 'has_skincare_subscription_in_any_state')
);
export const getHasActiveHairSupplementsSubscription = createSelector(
  getUserProfile,
  getOrNil(false, 'has_active_supplements_subscription')
);

export const getHasActiveHaircareSubscription = createSelector(
  getHasActiveHairTopicalsSubscription,
  getHasActiveHairSupplementsSubscription,
  (hasActiveHairTopicalsSubscription, hasActiveHairSupplementsSubscription) =>
    hasActiveHairTopicalsSubscription || hasActiveHairSupplementsSubscription
);

export const getHasHairAndSkinSubscriptions = createSelector(
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
  (hasActiveHaircareSubscription, hasActiveSkincareSubscription) =>
    hasActiveHaircareSubscription && hasActiveSkincareSubscription
);
export const getHasEitherHairOrSkinSubscription = createSelector(
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
  (hasActiveHaircareSubscription, hasActiveSkincareSubscription) =>
    (hasActiveHaircareSubscription || hasActiveSkincareSubscription) &&
    hasActiveHaircareSubscription !== hasActiveSkincareSubscription
);
export const getHasOnHoldSubscription = createSelector(
  getUserProfile,
  getOrNil(false, 'has_onhold_subscription')
);
export const getHasCanceledSubscription = createSelector(
  getUserProfile,
  getOrNil(false, 'has_canceled_subscription')
);
export const getHasSubscriptionFromCategory = createSelector(
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
  getHasOnHoldSubscription,
  (_state, { consultationCategory } = {}) => consultationCategory,
  (
    hasActiveHaircareSubscription,
    hasActiveSkincareSubscription,
    hasOnHoldSubscription,
    consultationCategory
  ) => {
    const hasActiveSubscription = hasActiveHaircareSubscription || hasActiveSkincareSubscription;
    if (hasActiveSubscription) {
      return consultationCategory === productsCategories.HAIRCARE
        ? hasActiveHaircareSubscription
        : hasActiveSkincareSubscription;
    }
    return hasOnHoldSubscription;
  }
);
export const getHasSubscription = createSelector(
  [getHasActiveSubscription, getHasOnHoldSubscription],
  (hasActiveSubscription, hasOnHoldSubscription) => hasActiveSubscription || hasOnHoldSubscription
);

export const getCanSeeFeedback = createSelector(
  getUserProfile,
  getOrNil(false, 'can_see_feedback')
);
export const getCanSubscribe = createSelector(getUserProfile, getOrNil(false, 'can_subscribe'));

export const getHasPaidCoreOrder = createSelector(
  getUserProfile,
  getOrNil(false, 'has_paid_core_order')
);
export const getPaidOrderWithPumpsCategories = createSelector(
  getUserProfile,
  getOrNil(false, 'paid_orders_with_pumps_categories')
);
export const getNextOrderFrom = createSelector(
  getUserProfile,
  getOrNil('consultation', 'next_order_from')
);
export const getHasFeedbackFromCheckoutCategory = createSelector(
  getUserProfile,
  (_state, { checkoutCategory } = {}) => checkoutCategory,
  (userProfile, checkoutCategory) =>
    getOrNil(false, `has_feedback_from_categories.${checkoutCategory}`)(userProfile)
);
export const getHaircareFeedbackOnOrder = createSelector(
  getUserProfile,
  getOrNil(null, 'feedback_on_order')
);
export const getSupplementsFeedbackOnOrder = createSelector(
  getUserProfile,
  getOrNil(null, 'supplements_feedback_on_order')
);
export const getSkincareFeedbackOnOrder = createSelector(
  getUserProfile,
  getOrNil(null, 'skincare_feedback_on_order')
);
// TODO default getCanSubscribeAtCart to true when backend is ready
export const getCanSubscribeAtCart = createSelector(
  getUserProfile,
  getOrNil(false, 'can_subscribe_at_cart')
);
export const getUserAddresses = createSelector(getUserState, getOrNil([], 'addresses'));
export const getUserAddressesStatus = createSelector(getUserState, get('addressesStatus'));
export const getPaymentMethods = createSelector(getUserState, getOrNil([], 'paymentMethods'));
export const getPaymentMethodsStatus = createSelector(getUserState, get('paymentMethodsStatus'));
export const getPayPalPaymentMethods = createSelector(
  getUserState,
  getOrNil([], 'paypalPaymentMethods')
);
export const getPaymentMethodsForSubscription = createSelector(getPaymentMethods, cards => cards);
export const getPayPalPaymentMethodsStatus = createSelector(
  getUserState,
  get('paypalPaymentMethodsStatus')
);
// TODO: merge this logic in payment methods (see comment above fetchPaymentMethods service)
export const getPayPalPreferredAccount = createSelector(
  getPayPalPaymentMethods,
  find(paypalAccount => paypalAccount !== undefined)
);
export const getRecommendationsStatus = createSelector(getUserState, get('recommendationsStatus'));
export const getUserInitials = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) => (firstName && lastName ? (firstName[0] + lastName[0]).toUpperCase() : '')
);

export const getHasAddresses = createSelector(getUserAddresses, addresses => addresses.length > 0);
export const getHasPaymentMethods = createSelector(
  getPaymentMethodsForSubscription,
  paymentMethods => paymentMethods.length > 0
);

export const getUserFragrance = createSelector(getUserProfile, getOrNil(null, 'fragrance'));

export const getUserSkincareFragrance = createSelector(getUserProfile, profileState => {
  const prefFragranceSkin = getOrNil(null, 'fragrance_skin')(profileState);
  if (prefFragranceSkin === 'FREE') return 'FRAGRANCE_FREE';
  return prefFragranceSkin;
});

export const getUserHairoilFragrance = createSelector(
  getUserProfile,
  getOrNil(null, 'fragrance_hairoil')
);

export const getUserFragranceByCategory = createSelector(
  getUserFragrance,
  getUserSkincareFragrance,
  (_, { category }) => category,
  (hairFragrance, skinFragrance, category) =>
    category === productsCategories.SKINCARE ? skinFragrance : hairFragrance
);

export const getUserFragranceIsSoldOut = createSelector(
  getUserProfile,
  getOrNil(false, 'is_fragrance_soldout')
);

export const getUserRecommendations = createSelector(getUserState, getOrNil([], 'recommendations'));

const getUserABTestGroup = createSelector(getUserProfile, get('ab_test_group'));

export const getProductFrequencyAbTestVariant = createSelector(
  getUserABTestGroup,
  get('product-frequency-recommendation')
);

export const getSupplementsVariant = createSelector(getUserState, get('supplementsVariant'));
export const getSupplementsVariantSlug = createSelector(getSupplementsVariant, get('slug'));

/* Discount the user is elligible to if he subscribes.
 default is a static 15 rate */
export const getUserSubscriptionDiscount = createSelector(
  getUserProfile,
  getOrNil(15, 'subscription_discount')
);

export const getHasGeneratedLivechat = createSelector(getUserState, get('hasGeneratedLivechat'));
export const getHasOpenedLivechat = createSelector(getUserState, get('hasOpenedLivechat'));

/* indicate whether or not the user will receive supp jars or supp pouches */
export const getMustHaveJars = createSelector(getUserProfile, getOr(true, 'must_have_jars'));

const getUserPreferredCurrency = createSelector(getUser, get('preferred_currency'));

export const getUserCurrency = createSelector(
  getUserGeolocationGuessedCountry,
  getUserPreferredCurrency,
  (geolocationGuessedCountry, userPreferredCurrency) => {
    if (userPreferredCurrency) {
      return userPreferredCurrency;
    }
    if (geolocationGuessedCountry && countriesCodeCurrency[geolocationGuessedCountry]) {
      return countriesCodeCurrency[geolocationGuessedCountry];
    }
    return currencies.USD;
  }
);

export const getProductsWithUnansweredQuestionsList = createSelector(
  getHasAnsweredSupplementsQuestions,
  getHasAnsweredStylingGelQuestions,
  (haveAnsweredSupplementsQuestion, haveAnsweredStylingGelQuestions) => {
    const productWithUnswanderedQuestionsList = [];
    if (!haveAnsweredSupplementsQuestion) {
      productWithUnswanderedQuestionsList.push(productsSlugs.SUPPLEMENT_CORE);
    }
    if (!haveAnsweredStylingGelQuestions) {
      productWithUnswanderedQuestionsList.push(productsSlugs.STYLING_GEL);
    }
    return productWithUnswanderedQuestionsList;
  }
);
export const getHasUnansweredQuestions = createSelector(
  getProductsWithUnansweredQuestionsList,
  productWithUnswanderedQuestionsList => productWithUnswanderedQuestionsList.length > 0
);

export const getHasCompletedOneOfConsultation = createSelector(
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
  (...args) => args.some(a => (isNil(a) ? false : a))
);

/**
 * Returns an array containing categories of the orders passed by the customer
 */
const getPaidOrdersCategories = createSelector(
  getUserProfile,
  getOrNil([], 'paid_orders_categories')
);

export const getHasOrdersByCategory = createSelector(
  getPaidOrdersCategories,
  paidOrdersCategories => {
    const hasHaircareOrders = includes('haircare')(paidOrdersCategories);
    const hasSkincareOrders = includes('skincare')(paidOrdersCategories);
    return {
      all: hasHaircareOrders && hasSkincareOrders,
      oneOf: hasHaircareOrders || hasSkincareOrders,
      haircare: hasHaircareOrders,
      skincare: hasSkincareOrders,
    };
  }
);

export const getMembershipPerksTitle = createSelector(
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
  (hasHaircareSubscription, hasSkincareSubscription) => {
    if (hasHaircareSubscription && hasSkincareSubscription) {
      return 'bothSubscription';
    }
    if (!hasHaircareSubscription && !hasSkincareSubscription) {
      return 'noSubscription';
    }
    if (hasHaircareSubscription && !hasSkincareSubscription) {
      return 'onlyHaircareSubscription';
    }
    return 'onlySkincareSubscription';
  }
);

export const getUserCurrentSubscriptionDiscount = createSelector(
  getUserProfile,
  get('subscription_discount')
);
