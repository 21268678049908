import find from 'lodash/find';
import filter from 'lodash/fp/filter';
import first from 'lodash/fp/first';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import maxBy from 'lodash/fp/maxBy';
import sortBy from 'lodash/fp/sortBy';
import sumBy from 'lodash/fp/sumBy';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import {
  ACCOUNT_DETAILS,
  CREATE_ACCOUNT,
  PAYMENT,
  SHIPPING_ADDRESS,
  SUCCESS as CHECKOUT_SUCCESS,
} from 'Apps/Checkout/constants/checkoutSteps';

import { couponLabelFromOrigin } from 'constants/coupons';
import { productsCategories } from 'constants/products';
import { ERROR, LOADING, SUCCESS } from 'constants/statuses';

import catalog from 'assets/content/cart/catalog';

import { getPreselectedCardId, getStatus } from 'dux/accountCards/selectors';
import { getIsAuthenticated } from 'dux/auth/selectors';
import { getAddressesData, getAddressesStatus } from 'dux/checkoutAddresses/selectors';
import { getIsPaymentReady } from 'dux/checkoutPayment/selectors';
import { getFetchedAccessoriesProductsMergedWithLocalProductsList } from 'dux/products/selectors';

const getCartV2 = state => state?.cartV2;

const getCartRecommendationDataV2 = createSelector(getCartV2, get('recommendation.data'));

export const getCartRecommendationStatusV2 = createSelector(
  getCartV2,
  get('recommendation.status')
);

export const getCartV2Status = createSelector(getCartV2, state => state.status);

export const getCartV2Data = createSelector(getCartV2, get('data'));

export const getCartV2Error = createSelector(getCartV2, get('error'));

const getCartCustomizationV2 = createSelector(getCartV2, get('customization'));

const getCartCustomizationDataV2 = createSelector(getCartCustomizationV2, get('data'));

const getCartItemsV2 = createSelector(getCartV2Data, get('cartitems'));

const getCartCouponsV2 = createSelector(getCartV2Data, get('coupons'));

const getCartGiftCardsV2 = createSelector(getCartV2Data, get('gift_cards'));

export const getComputedCartV2Items = createSelector(
  getCartV2Data,
  getCartCustomizationDataV2,
  (cartData, customizationData) =>
    cartData?.cartitems?.map(item => {
      const slug = item.variant_slug || item.product_type;
      const customizationContent = find(customizationData, { cart_item: item.pubkey });
      return {
        ...catalog[slug],
        customization: customizationContent,
        ...item,
      };
    })
);

export const getCartV2Currency = createSelector(getCartV2Data, get('currency'));

export const getCartV2UpdatingStatus = createSelector(getCartV2, get('isUpdating'));

export const getItemCustomizationError = createSelector(getCartCustomizationV2, get('error'));

export const getItemQuantityInCartV2 = createSelector(
  getCartItemsV2,
  sumBy(item => item.quantity)
);

export const getCartShippingAddressV2 = createSelector(getCartV2Data, get('shipping_address'));

export const getHasCompletedShippingAddressSectionV2 = createSelector(
  getCartShippingAddressV2,
  shippingAddress => !isNil(shippingAddress)
);

const getCartV2PaymentFromCustomer = createSelector(getCartV2Data, get('payment_from_customer'));

export const getCartV2IsFree = createSelector(getCartV2PaymentFromCustomer, total => total === 0);

const getHasCompletedPaymentSectionV2 = createSelector(
  getCartV2IsFree,
  getPreselectedCardId,
  (isFree, preselectedCardId) => {
    return isFree || !isNil(preselectedCardId);
  }
);

export const getNextStepFromStepPropV2 = createSelector(
  getHasCompletedPaymentSectionV2,
  getHasCompletedShippingAddressSectionV2,
  getCartV2IsFree,
  (_state, payload) => payload?.step,
  (hasCompletedPaymentSection, hasCompletedShippingAddressSection, isFree, step) => {
    switch (step) {
      case CREATE_ACCOUNT:
      case ACCOUNT_DETAILS:
        if (!hasCompletedShippingAddressSection || isFree) {
          return SHIPPING_ADDRESS;
        }
        if (hasCompletedPaymentSection || hasCompletedShippingAddressSection) {
          return PAYMENT;
        }
        return SHIPPING_ADDRESS;
      case SHIPPING_ADDRESS:
        if (isFree) {
          return CHECKOUT_SUCCESS;
        }
        return PAYMENT;
      case PAYMENT:
        return CHECKOUT_SUCCESS;
      default:
        return null;
    }
  }
);

export const getIsDisabledV2 = createSelector(
  getCartV2UpdatingStatus,
  getCartItemsV2,
  (isSaving, items) =>
    isSaving || !items?.some(p => p.quantity > 0 && p.category !== productsCategories.COUPON)
);

export const getUpsaleSectionRecommendationV2 = createSelector(
  getCartRecommendationDataV2,
  getFetchedAccessoriesProductsMergedWithLocalProductsList,
  (recommendation, accessoriesList) => {
    const upsaleSection = find(recommendation?.sections, {
      name: 'accessories_upsell',
    });
    // We only want to display 3 items
    return upsaleSection?.items?.slice(0, 3)?.map(product => {
      const price = find(accessoriesList, acc => {
        return acc.variantsContent?.[product.product_slug] || product.slug === acc.slug;
      })?.regularPrice;
      return { price, ...product, ...catalog?.[product.product_slug]?.upsale };
    });
  }
);

export const getTransactionId = createSelector(getCartRecommendationDataV2, get('transaction_id'));

export const getCheckoutStatusV2 = createSelector(
  getIsAuthenticated,
  getAddressesStatus,
  getCartV2Status,
  getStatus,
  getCartV2Error,
  (isAuthenticated, addressesStatus, cartStatus, cardsStatus, cartV2Error) => {
    // This trick is hide the error screen when user just signed up on the cart step
    const statuses = [cartV2Error?.code === 'customer_has_no_cart_v2' ? LOADING : cartStatus];
    if (isAuthenticated) {
      statuses.push(addressesStatus);
      statuses.push(cardsStatus);
    }

    if (statuses.includes(ERROR) || statuses.includes('error')) {
      return 'error';
    }

    if (statuses.includes(LOADING) || statuses.includes('loading')) {
      return 'loading';
    }

    // TODO: harmonize status string :'(
    if (statuses.every(status => status === 'success' || status === SUCCESS)) {
      return 'success';
    }

    return 'idle';
  }
);

const getDefaultAddressV2 = createSelector(
  getCartV2Data,
  getAddressesData,
  (cart, addresses) =>
    addresses?.find(a => a.pubkey === cart?.shipping_address) ||
    addresses?.find(a => a.primary) ||
    addresses?.[0]
);

export const getDefaultAddressPubkeyV2 = createSelector(getDefaultAddressV2, get('pubkey'));

export const getSelectedAddressV2 = createSelector(
  getCartV2Data,
  getAddressesData,
  (cart, addresses = []) => addresses?.find(({ pubkey }) => pubkey === cart?.shipping_address)
);

export const getCTAButtonLabelFromStepPropV2 = createSelector(
  getNextStepFromStepPropV2,
  getHasCompletedPaymentSectionV2,
  getHasCompletedShippingAddressSectionV2,
  (nextStep, hasCompletedPaymentSection, hasCompletedShippingAddressSection) => {
    switch (nextStep) {
      case SHIPPING_ADDRESS:
        if (hasCompletedShippingAddressSection) {
          return 'Continue to payment';
        }
        return 'Continue to shipping';
      case PAYMENT:
        if (hasCompletedPaymentSection) {
          return 'Place my order';
        }
        return 'Continue to payment';
      case CHECKOUT_SUCCESS:
        return 'Place my order';
      default:
        return 'Next';
    }
  }
);

export const getTotalOrderAmountV2 = createSelector(
  getCartV2PaymentFromCustomer,
  paymentFromCustomer => Math.round(100 * paymentFromCustomer)
);

export const isPayableV2 = createSelector(
  (_state, { expressCheckout }) => expressCheckout,
  getCartV2IsFree,
  getCartShippingAddressV2,
  (expressCheckout, isFree, shippingAddress) => !isFree && (expressCheckout || shippingAddress)
);

export const getPaymentFromCustomerV2 = createSelector(getCartV2Data, get('payment_from_customer'));

export const getCartTotalPriceV2 = createSelector(getCartV2Data, get('total_price'));

export const getCartTotalShippingV2 = createSelector(getCartV2Data, get('total_shipping'));

const getSortedDeliveryDates = createSelector(
  getCartV2Data,
  flow(
    getOr({}, 'shipping_estimate'),
    values,
    maxBy(estimate => dayjs(new Date(estimate.max)).format('YYYYMMDD')),
    values,
    map(dateString => dayjs(new Date(dateString))),
    sortBy(date => date.format('YYYYMMDD')),
    map(date => date.format('M/D'))
  )
);

export const getDeliveryByV2 = createSelector(getSortedDeliveryDates, sortedDeliveryDates => {
  if (!sortedDeliveryDates || sortedDeliveryDates.length === 0) {
    return '';
  }
  if (sortedDeliveryDates.length === 1) {
    return `Arrives by ${first(sortedDeliveryDates)}`;
  }
  return `Arrives between ${first(sortedDeliveryDates)} and ${last(sortedDeliveryDates)}`;
});

export const getGiftCardsV2 = createSelector(getCartGiftCardsV2, giftCards =>
  giftCards?.map(({ origin, label, property, total_price }) => ({
    property,
    label: isEmpty(label) ? couponLabelFromOrigin(origin) : label,
    value: total_price,
  }))
);

const getCoupons = createSelector(getCartCouponsV2, cartCoupons =>
  cartCoupons?.map(({ origin, label, property, total_price }) => ({
    property,
    label: isEmpty(label) ? couponLabelFromOrigin(origin) : label,
    value: total_price,
  }))
);

export const getCartCreditCouponsV2 = createSelector(getCoupons, filter({ property: 'credit' }));

export const getCartOfferCouponsV2 = createSelector(getCoupons, filter({ property: 'offer' }));

export const getIsCheckoutReadyForPaymentV2 = createSelector(
  getIsPaymentReady,
  getCartV2IsFree,
  (isPaymentReady, isFree) => isPaymentReady || isFree
);
